import axios from 'axios';
import { Container, FlatList, ScrollView, View, Input, Thumbnail } from 'native-base';
import { Image, Dimensions, TextInput, Text, StatusBar, TouchableOpacity, Platform } from 'react-native';
import React, { useState, useEffect, CSSProperties } from 'react';
import Environment from '../../../environment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';

const SearchByCategory = () => {

    const navigation = useNavigation();

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        getData();
    }, []);

    const getData = async () =>{
        const token = await AsyncStorage.getItem('token');
        const categoryId = await AsyncStorage.getItem('categoryId');
        await axios({
            url: `${Environment.api_url}/products?limit=15000&category=${categoryId}`,
            method: 'GET',
            headers:{
                'DOLAPIKEY': token
            }
        }).then((response) =>{
            setData(response.data);
            setFilteredData(response.data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });
    }

    const filter= (text) =>{
        if(text){
            const newData= data.filter(item => item.label.toLowerCase().includes(text.toLowerCase()) || item.ref.toLowerCase().includes(text.toLowerCase()) );
            setFilteredData(newData);
        }else{
            setFilteredData(data);
        }
    }

    const handlePress = (id) =>{
        AsyncStorage.setItem('productId', id);
        navigation.push('Details');
    }

    if(loading){
        return(
            <View 
                style={{
                    width: Dimensions.get('window').width,
                    flex: 1,
                    backgroundColor: 'white',
                    justifyContent: 'center', 
                    alignItems: 'center' 
                }}
            >
                <Image style={{width: 200, height: 70}} source={require('../../../assets/images/spinner.gif')}/>
            </View>
        );
    }

    return (
        <View style={{backgroundColor: 'white'}}>
            <StatusBar backgroundColor="#243c5c" barStyle="light-content" />
            {
                Platform.OS !== 'android' &&
                <View style={{ backgroundColor: '#243c5c', padding: 10 }}>
                    <AntDesign name='arrowleft' size={25} color={'white'} onPress={() => navigation.goBack()}/>
                </View>
            }
                <View style={{width: Dimensions.get('window').width}}>
                    <View style={{marginTop: 30, justifyContent: 'center', height: 50}}>
                        <Text style={{textAlign: 'center', fontSize: 30, color: '#243c5c'}}>Buscar productos</Text>
                    </View>
                    
                    <View style={{marginBottom: 10, paddingHorizontal: 15, paddingVertical: 10, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <Image source={require('../../../assets/images/buscar.png')}
                        style={{
                            padding: 10,
                            margin: 5,
                            height: 30,
                            width: 30,
                            resizeMode: 'stretch',
                            alignItems: 'center',
                        }}/>
                        <TextInput style={ {borderColor: '#EDEEEF', borderRadius: 50, borderWidth: 1, textDecorationLine: 'none', height: 50, paddingHorizontal: 20, flex: 1}} underlineColorAndroid="transparent" selectionColor={'blue'} placeholder={'Buscar...'} onChangeText={(text) => filter(text)} />
                    </View>

                    {
                        filteredData.length > 0 ?
                        <ScrollView style={{height: '82%'}}>
                            {
                                filteredData.map((item) =>{
                                    return(
                                        <TouchableOpacity key={item.id} onPress={() => handlePress(item.id)}>
                                            <View style={{flexDirection: 'row', marginHorizontal: 15, marginVertical: 5, borderBottomColor: '#EDEEEF', borderBottomWidth: 1, paddingBottom: 10}}>
                                                <View style={{width: '20%', justifyContent: 'center'}}>
                                                    <Image style={{width: 50, height: 50, borderRadius: 50}} source={{uri: item.url ? item.url : 'https://sistema.megaproductosfamiliares.com/public/theme/common/nophoto.png'}}/>
                                                </View>
                                                <View style={{width: '80%'}}>
                                                    <View style={{flexDirection: 'row'}}>
                                                        <Text ellipsizeMode='tail' numberOfLines={2} style={{ fontWeight: 'bold', fontSize: 15 }}>{item.ref}</Text>
                                                    </View>
                                                    <View style={{flexDirection: 'row'}}>
                                                        <Text style={{flex: 1, flexWrap: 'wrap', fontSize: 12}}>{item.label}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </TouchableOpacity>
                                    );
                                })
                            }
                        </ScrollView> :
                        <View style={{height: '82%', justifyContent: 'center', textAlign: 'center'}}>
                            <Text style={{textAlign: 'center'}}>No hay datos...</Text>
                        </View>
                    }
                </View>
            
        </View>
    );
}

export default SearchByCategory;