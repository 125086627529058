import axios from 'axios';
import { StatusBar, View } from 'native-base';
import React, { useState } from 'react'
import { Text, StyleSheet, Dimensions, Image } from 'react-native';
import { Input, Button } from 'native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import Environment from '../../../environment';

const Login = () => {

    const navigation = useNavigation();

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () =>{
        await axios.get(`${Environment.api_url}/login?login=${user}&password=${password}`)
        .then((response) =>{
            saveData(response);
        })
        .catch((error) =>{
            //TODO: Show toast
        });
    }

    const saveData = async (response) =>{
        await AsyncStorage.setItem('token', response.data.success.token);
        await AsyncStorage.setItem('user', user);

        navigation.replace('Tabs'); 
    }

    return (
        <View style={{backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            <StatusBar backgroundColor="#243c5c" barStyle="light-content" />
            <View style={{
                width: Dimensions.get('window').width, 
                height: '100%',
                backgroundColor: 'white',
                justifyContent: 'center', 
                alignItems: 'center',
                paddingHorizontal: 30
            }}>
                <Text style={{textAlign:"center", fontSize:15, letterSpacing: 5, color: '#047c8c'}}>CATÁLOGO</Text>
                <Image
                    source={require('../../../assets/images/dolibarr_logo.png')}
                    style={{width:200, height:45, objectFit: 'fill', marginBottom: 30}}
                />
                <Input value={user} onChangeText={(text)=> setUser(text)} placeholder='Usuario'/>
                <View style={{height: 10}}></View>
                <Input value={password} secureTextEntry={true} onChangeText={(text)=> setPassword(text)} placeholder='Contraseña'/>
                <Button style={{marginTop: 15, backgroundColor: '#243c5c', width: '100%'}} onPress={() => handleLogin()}>Ingresar</Button>
                <Text style={{textAlign:"center", fontSize:7, letterSpacing: 5, color: 'black', marginTop:100, marginBottom: -30}}>Powered by</Text>
                <Image
                    source={require('../../../assets/images/logo_coven_negro.png')}
                    style={{width:100, height:100}}
                />
            </View>
        </View>
    );
}


export default Login;