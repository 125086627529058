import { Button, Icon, Text } from 'native-base';
import React, {useState, useEffect} from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Home from '../pages/app/Home';
import Search from '../pages/app/Search';
import Categories from '../pages/app/Categories';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Environment from '../../environment';
import { useToast } from "react-native-toast-notifications";
import Profile from '../pages/app/Profile';
import { Ionicons } from '@expo/vector-icons';
import {useNavigation} from "@react-navigation/native";

const Tab = createBottomTabNavigator();

const Tabs = () => {
    const toast = useToast();
    const navigation = useNavigation();

    const [token, setToken] = useState('');
    

    useEffect(() =>{
        getToken();
        getUser();
    }, [token]);

    const getToken = async () =>{
        try{
            const savedToken = await AsyncStorage.getItem('token');
            if(savedToken !== null){
                setToken(savedToken);
            }
        }catch(ex){
            setToken('');
        } 
    }

    const getUser = async () =>{
        if(token !== ''){
            await axios.get(`${Environment.api_url}/users/info`, {
                headers: {
                    DOLAPIKEY: token
                }
            }).then( async (response) =>{
                await AsyncStorage.setItem('user', JSON.stringify(response.data));
            }).catch((error) =>{
                navigation.push('Login');
            });
        }
    }

    return (
        <Tab.Navigator>
          <Tab.Screen name="Inicio" component={Home} options={{headerShown: false, tabBarActiveTintColor: '#243c5c', tabBarIcon: ({ focused, size }) =>{
            return <Ionicons name='home' size={size} color={focused ? '#243c5c' : 'gray'}/>
          }}}/>
          <Tab.Screen name="Buscar" component={Search} options={{headerShown: false, tabBarActiveTintColor: '#243c5c', tabBarIcon: ({ focused, size }) =>{
            return <Ionicons name='search' size={size} color={focused ? '#243c5c' : 'gray'}/>
          }}}/>
          <Tab.Screen name="Categorías" component={Categories} options={{headerShown: false, tabBarActiveTintColor: '#243c5c', tabBarIcon: ({ focused, size }) =>{
            return <Ionicons name='cube' size={size} color={focused ? '#243c5c' : 'gray'}/>
          }}}/>
          <Tab.Screen name="Perfil" component={Profile} options={{headerShown: false, tabBarActiveTintColor: '#243c5c', tabBarIcon: ({ focused, size }) =>{
            return <Ionicons name='person' size={size} color={focused ? '#243c5c' : 'gray'}/>
          }}}/>
        </Tab.Navigator>
    );
}

export default Tabs;