
import React, { useEffect, useState } from 'react'
import { Text, Dimensions, Image, TouchableOpacity, Platform } from 'react-native';
import Environment from '../../../environment';
import { Container, ScrollView, View, Actionsheet, useDisclose } from 'native-base';
import { useToast } from "react-native-toast-notifications";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

const Details = () => {

    const { isOpen, onOpen, onClose } = useDisclose();
    const navigation = useNavigation();

    const [data, setData] = useState({});
    const [categories, setCategories] = useState([]);
    const [prices, setPrices] = useState({});
    const [loading, setLoading] = useState(true);

    const [pressed1,setPressed1]=useState(true);
    const [pressed2,setPressed2]=useState(false);
    const [pressed3,setPressed3]=useState(false);
    const [pressed4,setPressed4]=useState(false);
    const [pressed5,setPressed5]=useState(false);

    useEffect(() =>{
        getData();
    }, []);

    const getData = async () =>{
        const id = await AsyncStorage.getItem('productId');
        const token = await AsyncStorage.getItem('token');
        await axios({
            url: `${Environment.api_url}/products/${id}`,
            method: 'GET',
            headers: {
                'DOLAPIKEY': token
            }
        }).then(async (response) =>{
            await axios({
                url: `${Environment.api_url}/products/${id}/categories`,
                method: 'GET',
                headers: {
                    'DOLAPIKEY': token
                }
            }).then(async (secondResponse) =>{
                setData(response.data);
                setCategories(secondResponse.data);
                setPrices(response.data.multiprices_ttc);
                setLoading(false);
            }).catch((error) =>{
                console.log(error);
            });
        }).catch((error) =>{
            console.log(error);
        });
    }
    

    if(loading){
        return(
            <View 
                style={{
                    width: Dimensions.get('window').width,
                    flex: 1,
                    backgroundColor: 'white',
                    justifyContent: 'center', 
                    alignItems: 'center' 
                }}
            >
                <Image style={{width: 200, height: 70}} source={require('../../../assets/images/spinner.gif')}/>
            </View>
        );
    }

    return (
        <View style={{backgroundColor: 'white'}}>
            {
                Platform.OS !== 'android' &&
                <View style={{ backgroundColor: '#243c5c', padding: 10 }}>
                    <AntDesign name='arrowleft' size={25} color={'white'} onPress={() => navigation.goBack()}/>
                </View>
            }
            <View style={{width: '99%', flexDirection: 'row', marginTop: 10, justifyContent: 'space-between', marginHorizontal: 'auto'}}>
                <View></View>
                <View>
                    <TouchableOpacity onPress={onOpen}>
                        <Image resizeMode='contain' style={{width: 50, height: 50, padding: 5}} source={require('../../../assets/images/menu.png')}/>
                    </TouchableOpacity>
                </View>
            </View>
            <ScrollView style={{height: '100%'}}>
                <View
                    style={{
                        width: Dimensions.get('window').width,
                        height: 300,
                        backgroundColor: 'white',
                        justifyContent: 'center', 
                        alignItems: 'center'
                    }}
                >
                    <Image resizeMode='contain' style={{width: 300, height: 300}} source={{uri: data.url ? data.url : 'https://sistema.megaproductosfamiliares.com/public/theme/common/nophoto.png'}}/>
                </View>
                <View style={{padding: 20, borderBottomColor: '#EDEEEF', borderBottomWidth: 1, marginBottom: 0}}>
                    <Text style={{fontWeight: 'bold', fontSize: 20, textTransform: 'capitalize', textAlign: 'center'}}>{data.label}</Text>
                    <Text style={{fontSize: 17, textAlign: 'center', color: 'grey'}}>{data.ref}</Text>

                    {/* <Text>Marca: <Text>{categories.map((item) => item.label)}</Text></Text>s */}
                    
                    {pressed1 && <Text style={{textAlign: 'center', marginTop: 15, fontSize: 30, color: 'grey'}}>${parseFloat(prices["1"]).toFixed(2)}</Text>}
                    {pressed2 && <Text style={{textAlign: 'center', marginTop: 15, fontSize: 30, color: 'grey'}}>${parseFloat(prices["2"]).toFixed(2)}</Text>}
                    {pressed3 && <Text style={{textAlign: 'center', marginTop: 15, fontSize: 30, color: 'grey'}}>${parseFloat(prices["3"]).toFixed(2)}</Text>}
                    {pressed4 && <Text style={{textAlign: 'center', marginTop: 15, fontSize: 30, color: 'grey'}}>${parseFloat(prices["4"]).toFixed(2)}</Text>}
                    {pressed5 && <Text style={{textAlign: 'center', marginTop: 15, fontSize: 30, color: 'grey'}}>${parseFloat(prices["5"]).toFixed(2)}</Text>}
                </View>
                <View style={{marginHorizontal: 10}}>
                    {
                        categories.map((item) =>{
                            return(
                                <View key={item.id} style={{alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                    <Image resizeMode='contain' style={{width: 200, height: 200}} source={{uri: item.description ? item.description : 'https://sistema.megaproductosfamiliares.com/public/theme/common/nophoto.png'}}/>
                                </View>
                            );
                        })
                    }
                </View>
            </ScrollView>
            <Actionsheet isOpen={isOpen} onClose={onClose}>
                <Actionsheet.Content>
                <Actionsheet.Item
                    onPress={() =>{
                        setPressed1(true);
                        setPressed2(false);
                        setPressed3(false);
                        setPressed4(false);
                        setPressed5(false);
                        onClose();
                    }}>Precio de venta 1</Actionsheet.Item>
                <Actionsheet.Item
                    onPress={() =>{
                        setPressed1(false);
                        setPressed2(true);
                        setPressed3(false);
                        setPressed4(false);
                        setPressed5(false);
                        onClose();
                    }}>Precio de venta 2</Actionsheet.Item>
                <Actionsheet.Item
                    onPress={() =>{
                        setPressed1(false);
                        setPressed2(false);
                        setPressed3(true);
                        setPressed4(false);
                        setPressed5(false);
                        onClose();
                    }}>Precio de venta 3</Actionsheet.Item>
                <Actionsheet.Item
                    onPress={() =>{
                        setPressed1(false);
                        setPressed2(false);
                        setPressed3(false);
                        setPressed4(true);
                        setPressed5(false);
                        onClose();
                    }}>Precio de venta 4</Actionsheet.Item>
                <Actionsheet.Item
                    onPress={() =>{
                        setPressed1(false);
                        setPressed2(false);
                        setPressed3(false);
                        setPressed4(false);
                        setPressed5(true);
                        onClose();
                    }}>Precio de venta 5</Actionsheet.Item>
                <Actionsheet.Item onPress={onClose} color='red.500'>Cancelar</Actionsheet.Item>
                </Actionsheet.Content>
            </Actionsheet>
        </View>
    );
}

export default Details;