import React, { useEffect, useState } from 'react'
import { Actionsheet, useDisclose, View } from 'native-base';
import { Dimensions, StatusBar, Text, Image, TouchableOpacity, Linking, ScrollView } from 'react-native';
import Environment from '../../../environment';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';

const Categories = () => {

    const navigation = useNavigation();
    const { isOpen, onOpen, onClose } = useDisclose();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [id, setId] = useState(0);

    useEffect(() =>{
        getData();
    }, []);

    const getData = async () =>{
        const token = await AsyncStorage.getItem('token');

        await axios({
            url: `${Environment.api_url}/categories?type=product`,
            method: 'GET',
            headers: {
                'DOLAPIKEY': token
            }
        }).then((response) => {
            setData(response.data);
            setLoading(false);
        }).catch((error) =>{

        })
    }

    const handleSelect = (id) =>{
        AsyncStorage.setItem('categoryId', id);
        navigation.push('SearchByCategory');
    }

    const openActionSheet = (id) =>{
        setId(id);
        onOpen();
    }

    const download = async (lista) =>{
        await Linking.openURL(`${Environment.cataloge_url}?category=${id}&lista=${lista}`);
    }

    if(loading){
        return(
            <View 
                style={{
                    width: Dimensions.get('window').width,
                    flex: 1,
                    backgroundColor: 'white',
                    justifyContent: 'center', 
                    alignItems: 'center' 
                }}
            >
                <Image style={{width: 200, height: 70}} source={require('../../../assets/images/spinner.gif')}/>
            </View>
        );
    }

    return (
        <View style={{backgroundColor: 'white', flex: 1}}>
            <StatusBar backgroundColor="#243c5c" barStyle="light-content" />
            <View style={{width: Dimensions.get('window').width, flex: 1}}>
                <View style={{marginTop: 30, justifyContent: 'center', height: 50}}>
                    <Text style={{textAlign: 'center', fontSize: 30, color: '#243c5c'}}>Categorías</Text>
                </View>

                <ScrollView style={{height: '89%', marginTop: 10}}>
                    {
                        data.map((item) => {
                            return(
                                <View key={item.id} style={{flexDirection: 'row', marginHorizontal: 15, marginVertical: 5, borderBottomColor: '#EDEEEF', borderBottomWidth: 1, paddingBottom: 10}}>
                                    <View style={{width: '80%', justifyContent: 'center'}}>
                                        <TouchableOpacity onPress={() => handleSelect(item.id)}>
                                            <Text ellipsizeMode='tail' numberOfLines={2} style={{ fontWeight: 'bold', fontSize: 15 }}>{item.label}</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={{width: '20%', alignItems: 'center'}}>
                                        <TouchableOpacity onPress={() => openActionSheet(item.id)}>
                                            <Image style={{width: 30, height: 30, padding: 5}} source={require('../../../assets/images/descargar.png')}/>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            );
                        })
                    }
                </ScrollView>
                <Actionsheet isOpen={isOpen} onClose={onClose}>
                    <Actionsheet.Content>
                        <Actionsheet.Item onPress={() => download(1)}>Precio de venta 1</Actionsheet.Item>
                        <Actionsheet.Item onPress={() => download(2)}>Precio de venta 2</Actionsheet.Item>
                        <Actionsheet.Item onPress={() => download(3)}>Precio de venta 3</Actionsheet.Item>
                        <Actionsheet.Item onPress={() => download(4)}>Precio de venta 4</Actionsheet.Item>
                        <Actionsheet.Item onPress={() => download(5)}>Precio de venta 5</Actionsheet.Item>
                    </Actionsheet.Content>
                </Actionsheet>
            </View>
        </View>
    );
}

export default Categories;