import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { NativeBaseProvider } from 'native-base';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Presentation from './src/pages/Presentation';
import Login from './src/pages/auth/Login';
import { ToastProvider } from 'react-native-toast-notifications'
import Tabs from './src/components/Tabs';
import Details from './src/pages/app/Details';
import SearchByCategory from './src/pages/app/SearchByCategory';
import { SafeAreaProvider } from 'react-native-safe-area-context';

export default function App() {

  const Stack = createStackNavigator();

  return (
    <SafeAreaProvider>
      <ToastProvider>
        <NativeBaseProvider>
          <NavigationContainer>
            <Stack.Navigator
              initialRouteName='Presentation'
            >
              <Stack.Screen
                name='Presentation'
                component={Presentation}
                options={
                  {headerShown: false}
                }
              />
              <Stack.Screen
                name='Login'
                component={Login}
                options={
                  {headerShown: false}
                }
              />
              <Stack.Screen
                name="Tabs"
                component={Tabs}
                options={
                  { headerShown: false }
                }
              />
              <Stack.Screen
                name="Details"
                component={Details}
                options={
                  { headerShown: false }
                }
              />
              <Stack.Screen
                name="SearchByCategory"
                component={SearchByCategory}
                options={
                  { headerShown: false }
                }
              />
            </Stack.Navigator>
          </NavigationContainer>
        </NativeBaseProvider>
      </ToastProvider>
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
