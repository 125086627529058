
import React, { useEffect, useState } from 'react'
import { Text, Dimensions, Image, StatusBar, ScrollView, TouchableOpacity } from 'react-native';
import Environment from '../../../environment';
import { Container, View } from 'native-base';

import { useToast } from "react-native-toast-notifications";
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Fragment } from 'react';
import { useNavigation } from '@react-navigation/native';

const Home = () => {

    const toast = useToast();
    const navigation = useNavigation();

    const [categories, setcategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        getData();
    }, []);

    const getData = async () =>{
        const token = await AsyncStorage.getItem('token');

        await axios({
            url: `${Environment.api_url}/categories?type=product`,
            method: 'GET',
            headers: {
                'DOLAPIKEY': token
            }
        }).then(async (categoriesResponse) =>{
            await axios({
                url: `${Environment.api_url}/products?sortfield=t.rowid&sortorder=DESC&limit=10`,
                method: 'GET',
                headers: {
                    'DOLAPIKEY': token
                }
            }).then((productsResponse) =>{
                setProducts(productsResponse.data);
                setcategories(categoriesResponse.data);

                setLoading(false);
            }).catch((error) =>{

            });
        }).catch((error) =>{

        });
    }

    const handlePressProduct = (id) =>{
        AsyncStorage.setItem('productId', id);
        navigation.push('Details');
    }

    const handlePressCategory = (id) =>{
        AsyncStorage.setItem('categoryId', id);
        navigation.push('SearchByCategory');
    }

    if(loading){
        return(
            <View 
                style={{
                    width: Dimensions.get('window').width,
                    flex: 1,
                    backgroundColor: 'white',
                    justifyContent: 'center', 
                    alignItems: 'center' 
                }}
            >
                <Image style={{width: 200, height: 70}} source={require('../../../assets/images/spinner.gif')}/>
            </View>
        );
    }

    return (
        <View style={{backgroundColor: 'white', flex: 1}}>
            <StatusBar backgroundColor="#243c5c" barStyle="light-content" />
            <ScrollView>
                <View style={{alignItems: 'center', alignContent: 'center', marginTop: 10, paddingBottom: 10, borderBottomColor: 'grey', borderBottomWidth: 1}}>
                    <Image source={require('../../../assets/images/logo_company.png')} style={{height: 200, width: 350, resizeMode: 'contain'}}/>
                </View>
                <View style={{paddingBottom: 10, borderBottomColor: 'grey', borderBottomWidth: 1}}>
                    <Text style={{fontWeight:'bold', fontSize:24, marginVertical:20, color:"#243c5c", paddingHorizontal: 10}}>Últimos Productos</Text>
                    <ScrollView horizontal>
                        {
                            products.map((item)=>{
                                return(
                                    <Fragment key={item.id}>
                                        <TouchableOpacity onPress={() => handlePressProduct(item.id)}>
                                            <View style={{marginLeft: 10, marginRight: 10, borderRadius: 10, borderColor: '#243c5c', borderWidth: 1, marginVertical: 5, padding: 5}}>
                                                <Image style={{width: 200, height: 200 }} resizeMode="contain" source={{uri: item.url ? item.url : 'https://sistema.megaproductosfamiliares.com/public/theme/common/nophoto.png'}}/>
                                            </View>
                                        </TouchableOpacity>
                                    </Fragment>
                                );
                            })
                        }
                    </ScrollView>
                </View>
                <View >
                    <Text style={{fontWeight:'bold', fontSize:24, marginVertical:20, color:"#243c5c", paddingHorizontal: 10}}>Categorías</Text>
                    <View>
                        {
                            categories.map((item) =>{
                                return(
                                    <Fragment key={item.id}>
                                        <TouchableOpacity onPress={() => handlePressCategory(item.id)}>
                                            <View style={{marginLeft: 15, marginRight: 10, borderRadius: 10, borderColor: '#243c5c', borderWidth: 1, marginVertical: 5}}>
                                                <Image resizeMode='contain' style={{width: '100%', height: 200, marginVertical: 5, }} source={{uri: item.description ? item.description : 'https://sistema.megaproductosfamiliares.com/public/theme/common/nophoto.png'}}/>
                                            </View>
                                        </TouchableOpacity>
                                    </Fragment>
                                );
                            })
                        }
                    </View>
                </View>
            </ScrollView>
        </View>
    );
}

export default Home;