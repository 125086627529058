import React, { useEffect, useState } from 'react'
import { Button, ScrollView, View } from 'native-base';
import { Dimensions, StatusBar, Text, Image, TouchableOpacity } from 'react-native';
import Environment from '../../../environment';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';

const Profile = () => {

    const navigation = useNavigation();
    const [data, setData] = useState({});
    const [loading, setloading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () =>{
        const token = await AsyncStorage.getItem('token');

        await axios({
            url: `${Environment.api_url}/users/info`,
            method: 'GET',
            headers: {
                'DOLAPIKEY': token
            }
        }).then((response) => {
            setData(response.data);
            setloading(false);
        }).then((error) => {
            console.log(error);
        });
    }

    const cerrarSesion = async () =>{
        await AsyncStorage.clear();
        navigation.replace('Login');
    }

    if(loading){
        return(
            <View 
                style={{
                    width: Dimensions.get('window').width,
                    flex: 1,
                    backgroundColor: 'white',
                    justifyContent: 'center', 
                    alignItems: 'center' 
                }}
            >
                <Image style={{width: 200, height: 70}} source={require('../../../assets/images/spinner.gif')}/>
            </View>
        );
    }

    return (
        <View style={{backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            <StatusBar backgroundColor="#243c5c" barStyle="light-content" />
            <View style={{
                width: Dimensions.get('window').width, 
                height: '100%',
                backgroundColor: 'white',
                justifyContent: 'center', 
                alignItems: 'center'
            }}>
                <Image style={{width: 150, height: 150, borderRadius: 100}} source={{uri: data.note_private ? data.note_private : 'https://sistema.megaproductosfamiliares.com/public/theme/common/user_anonymous.png'}}/>
                <Text style={{fontSize: 18, fontWeight: 'bold', marginTop: 10}}>{data.firstname} {data.lastname}</Text>
                <Text>{data.email}</Text>
                <Button onPress={() => cerrarSesion()} style={{marginTop: 15, backgroundColor: '#243c5c'}}>Cerrar Sesión</Button>
            </View>
        </View>
    );
}

export default Profile;