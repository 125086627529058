import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react'
import { Image } from 'react-native';
import { View } from 'react-native';
import { Text, StyleSheet, StatusBar } from 'react-native';
import Environment from '../../environment';

const Presentation = () => {

    const [token, setToken] = useState('');

    const navigation = useNavigation();

    useEffect(() =>{
        validate();        

        setTimeout(() =>{
            if(token !== ''){
                navigation.replace('Tabs');
            }else{
                navigation.replace('Login');
            }
        }, 1000);
    }, [token]);

    const validate = async () =>{
        try{
            const savedToken = await AsyncStorage.getItem('token');
            if(savedToken !== null){
                setToken(savedToken);
            }
        }catch(ex){
            setToken('');
        }
    }

    return (
        <View style={styles.content}>
            <StatusBar style={styles.statusbar} barStyle="light-content"/>
            <Text style={styles.title}>CATÁLOGO</Text>
            <Image
                source={require('../../assets/images/dolibarr_logo.png')}
                style={styles.logo}
            />
            <Image
                source={require('../../assets/images/logo_coven_negro.png')}
                style={styles.coven}
            />
            <Text style={styles.version}>V.{Environment.version}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    content:{
        flex:1,
        alignContent:"center",
        justifyContent:"center",
        alignItems:"center"
    },
    statusbar: {
        backgroundColor: '#243c5c'
    },  
    title:{
        textAlign:"center",
        fontSize:15,
        letterSpacing: 5,
        color: '#047c8c'
    },
    logo:{
        width:200,
        height:45,
        objectFit: 'fill'
    },  
    coven:{
        width:150,
        height:150,
        marginTop:250
    },
    version:{
        textAlign:"center",
        fontSize:12,
    }
});

export default Presentation;